export const homeObjOne = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Image Metrics AR',
  headline: 'Introducing our Real-Time 3D Body Tracking',
  list: [
    'Empowers users to create the most unique short video content',
    'Create compelling AR gaming experiences'
  ],
  buttonLabel: 'Find Out More',
  imgStart: '',
  video: 'Videos/0001-0183.mp4'
}

export const homeObjTwo = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Image Metrics AR',
  headline: 'RealTime Tracking',
  description: 'Image Metrics body tracking technology runs real-time on a wide variety of mobile devices on both iOS and Android platforms. There is no requirement for special hardware.  ',
  buttonLabel: 'Learn More',
  imgStart: 'start',
  video: 'Videos/spinningLogo.mp4'
}

export const homeObjThree = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Image Metrics AR',
  headline: 'Unmatched Quality',
  description: 'Highest Fidelity Tracking Available ',
  list: [
    'Detailed body motion, up to 32 3D features and orientations',
    'Smooth joint tracking through low light conditions and motion blur',
    'Trained on over 250,000 proprietary annotated images'
  ],
  buttonLabel: 'Take Action Now',
  imgStart: '',
  img: 'images/car-transport-future.svg',
  alt: 'Image Desc'
}

export const homeObjFour = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: '',
  headline: 'Robustness is Our Middle Name',
  list: [
    'Lower body robustness at extreme camera angles',
    'Robust handling of multiperson scenarios',
    'Handles occluded joints'
  ],
  buttonLabel: 'Find Out More',
  imgStart: 'start',
  img: 'images/fly-transport-people.svg',
  alt: 'Image Desc'
}