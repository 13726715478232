export const homeObjOne = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Social Stuff',
  headline: 'Lorem Ipsum blah balh loren ipsum',
  description: 'Lorem ipsump lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum ',
  buttonLabel: 'Find Out More',
  imgStart: '',
  img: 'images/dashboard-hand-tech.svg',
  alt: 'Image Desc'
}

export const homeObjTwo = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Social Stuff',
  headline: 'Lorem Ipsum blah balh loren ipsum',
  description: 'Lorem ipsump lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum ',
  buttonLabel: 'Find Out More',
  imgStart: 'start',
  img: 'images/dashboard-screen-tech.svg',
  alt: 'Image Desc'
}

export const homeObjThree = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Social Stuff',
  headline: 'Lorem Ipsum blah balh loren ipsum',
  description: 'Lorem ipsump lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum ',
  buttonLabel: 'Find Out More',
  imgStart: '',
  img: 'images/developer-scientist-people.svg',
  alt: 'Image Desc'
}

export const homeObjFour = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Social Stuff',
  headline: 'Lorem Ipsum blah balh loren ipsum',
  description: 'Lorem ipsump lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum ',
  buttonLabel: 'Find Out More',
  imgStart: 'start',
  img: 'images/eye-scan-medical-science.svg',
  alt: 'Image Desc'
}